
 
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { injectIntl } from "gatsby-plugin-intl"

const PrivacyPolicyPage = ({ intl }) => {

const isEnglish = () => {
return (intl.locale === "en")
}

return (

<Layout> 

<SEO
    site={siteMetadata}
    title="Privacy Policy"
    description="CIGAM pledges to coach students using best practices while adhering to ethical principles in their journey to studying and living abroad."
   />

<section id="privacypolicy">
<div className="container" >
      
    <div className="text-center mb-5 mt-5">
      
        <h1>Privacy Privacy</h1>
        <h2>Introduction </h2>
        <p>CIGAM GLOBAL CO., LTD. ("CIGAM", “us” or “we”) is committed to protecting your privacy and security of your personal data. This following Privacy Policy describes what information we collect from you when you visit <a href="cigamglobal.com">cigamglobal.com</a> (the “Site”), including the Site subscription pages or other websites or apps that post a link to this Privacy Statement, and how we use this information. Please read this Privacy Policy carefully to understand our privacy practices. This Privacy Policy was last updated in November 2020.</p>
        <p>In addition, information that you submit to CIGAM in response to an email request for information or other outreach from CIGAM, or through CIGAM’s employment application processes, will be treated in accordance with this Privacy Statement.</p>
        <p>CIGAM may, in its discretion, amend this Privacy Statement from time to time. To ensure you are able to remain informed about the information we collect and how we use it, material changes to our statement will be reflected here. This Site may contain links to external sites which are not governed by this Privacy Statement. CIGAM does not take responsibility for the privacy practices of any third party sites to which we link. We encourage you to review the privacy policies of any such sites before you submit information there. </p>
        <p>By browsing our website and expressly consenting where required (for instance, when submitting the contact form), you agree to be bound by this Privacy Statement.</p>
    </div>
    <div>
      <h2> What data we gather during your visit</h2>
      <p><i>Information we gather:  </i></p>
      <p>We automatically collect certain information from you when you visit the Site. This data is used in conjunction with Google Analytics to gather metrics on site usage including the geographic location of visitors and pathways navigated through the website. The information we collect is not linked to anything that identifies you as an individual. Your session will be tracked but you will remain anonymous as a user. As part of <a href="www.googleanalytics.com">Google Analytics</a>, we also gather device and browser-related information. </p>
      <p>In addition, we sometimes aggregate demographic information and the types of systems and browsers of users. CIGAM also may conduct user surveys on the Web or use technologies to provide CIGAM with information on a number of areas, such as user identity, user viewing habits, whether or not users found what they were searching for, whether the Site content is relevant to user needs, and the like.</p>
      <p><i>Information you may provide: </i></p>
      <p>In order to take full advantage of our services, certain tools, such as the <a href="https://www.cigamglobal.com/contact-us/">contact us</a> form may request additional contact information and/or online identifiers. Some personal information we may collect include names, e-mail addresses, phone numbers, unique personal identifiers, financial information, areas of interest and/or demographic information.</p>
      <p>Personal information may also be collected via subscription to the newsletter, registration to conferences or other CIGAM-organized events.</p>
      <p>If you do not provide such information, you will not be able to receive services from CIGAM, participate in certain CIGAM-organized events, subscribe for email newsletters and seek additional information regarding our services or employment opportunities. </p>
    </div>
    <div>
      <h2>How we use your data </h2>
      <p>The purposes and uses of your personal information will depend on the use of the Site and the personal information provided. We process your personal information:</p>
        <ol type="i">
          <li>for the purposes of safeguarding the legitimate interests pursued by CIGAM. This includes:</li>
          <ul>
            <li>Informing you about updates to the service and notifying you about other products and services offered by CIGAM that may be of interest to you, including information regarding publications and events.</li>
            <li>Tailoring your experience at the Site with relevant CIGAM materials.</li>
            <li>Understanding the Site’s user population, identifying subject areas of interest, and determining whether the Site is designed to work with the computer settings of a majority of our visitors.</li>
            <li>Measuring and improving the effectiveness of CIGAM marketing programs across different channels.</li>
            <li>Improving our web content and navigation.</li>
          </ul>
          <li>on the basis of your consent. Insofar as you have granted us consent to the processing of personal information for specific purposes (for example, your contact us form for a consultation with CIGAM), the lawfulness of such processing is based on your consent. You may withdraw your consent at any time.</li>
          <li>for compliance with a legal obligation. This includes anti-fraud and anti-money laundering measures as well as tax and social security requirements.</li>
        </ol> 
      <p>We will not sell, share, or rent or otherwise make available your personal information to other parties, except that we may disclose the information to third parties who perform services on our behalf and have a need to access the information in connection with those services. Any third parties will only process this information to the extent to which and within the limits that CIGAM itself is permitted to process that data. In addition, CIGAM may disclose your contact information in response to inquiries by bona-fide rights owners in connection with allegations of infringement of copyright or other proprietary rights arising from information that you have posted on the Site or otherwise provided to CIGAM.</p>
    </div>
    <div>
      <h2>With whom we may share your data</h2>
      <p>The third parties with whom we may need to share personal information to help us provide services and products to you and to run our Website include:</p>
      <ul>
        <li>our subsidiaries or affiliates;</li>
        <li>our advisors;</li>
        <li>our third-party service providers who process the information on our behalf to help run some of our internal business operations including email distribution, IT services and marketing and events services;</li>
        <li>our business partners for publications and events co-organized by CIGAM and them;</li>
        <li>law enforcement bodies in order to comply with any legal obligation or court order.</li>
      </ul>
      <p>In addition, the Site may be viewed and accessed anywhere in the world including countries that may not have laws regulating the use and transfer of personally identifiable information. By using the Site, submitting information through this site, or submitting personal information to CIGAM through other means you voluntarily consent to such international transfer and hosting of such information to those countries and parties.</p>
      <h2>Retention of your information</h2>
      <p>CIGAM retains your personal information for so long as is necessary to fulfil the purpose for which it was collected. We may retain your personal information for longer if they may be the subject of a legal claim, or may otherwise be relevant for future litigation.</p>
      <p><i>Marketing:</i></p>
      <ul>
        <li>If at any time, you prefer not to receive further communications from us in any or all forms you will have the ability to unsubscribe from such communications by means of a link provided in every e-mail that is sent to you by us. When subscribing to CIGAM e-mail newsletters, you are given the opportunity to select which promotions, news, and information you would like to receive at the time of sign up, and you will have the opportunity to unsubscribe from such communications.</li>
      </ul>
      <p><i>Cookies and other tracking technologies:</i></p>
      <p>CIGAM uses cookies on this Site and in our communications with you to keep track of your visit to our Site and our communications with you. A “cookie” is a small amount of data sent from a Web server to your browser and stored on your computer’s hard drive. Other tracking technologies work similarly to cookies and place a small amount of data on your devices to monitor your website activity to allow us to collect information about how you use our Site and our services. With most internet browsers, you can erase cookies from your computer hard drive or block all cookies.<br/>Once you have given your consent to use cookies we shall store a cookie on your computer or device to remember this for next time. If you wish to withdraw consent at any time you will need to delete our cookies using your web browsers settings. Please be advised that certain sections or functionalities of the Site may be inaccessible to you if your browser does not accept cookies.</p>
      <p><i>We use the following types of cookies:</i><br/>“persistent cookies” to improve your experience when using the Site.<br/>“session cookies” to enable your use of our Site and to remember your settings. Session cookies are deleted automatically at the end of your visit.<br/>“web analytics cookies”. These cookies are used by us or third-party providers to analyze how the Site and our services are used, including the following types:<br/></p>
      <p><a href="www.adobenalytics.com">Adobe Analytics</a>: This website uses Adobe Analytics, web analytics and marketing service provided by Adobe Systems Incorporated (“Adobe”). Adobe uses HTTP “cookies” and similar technologies to collect information to measure and understand how users use this website. This includes user activity tracking on this website, including pages, visited and links clicked. CIGAM does this to provide you with experiences and marketing messages based on your likely interests. The information generated by the cookie about your use of the Site (including your IP address) will be transmitted to and stored by Adobe. Adobe may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Adobe’s behalf. Adobe will not associate your IP address with any other data held by Adobe. You may also refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do opt-out you may not be able to use the full functionality of this website. </p>
      <p>Web beacons: CIGAM includes a web beacon, which is a graphic image, in a majority of the HTML email messages we send. CIGAM uses web beacons, alone or in conjunction, with cookies to compile information about your usage of CIGAM websites and interaction with emails from CIGAM. We use the capability to determine whether or not an email has been received, opened, or when an URL has been clicked inside of an email that directs you to one of CIGAM’s websites. These web beacons are used to: (i) operate and improve CIGAM websites, services, and email communications, (ii) send emails in a format user can read and (iii) track the aggregate number of emails opened. The web beacon does not collect your personal information, however, the information compiled by the web beacon allows us to tie a user to information CIGAM has collected in the data collection process as set out in this Privacy Statement. You may refuse the use of web beacons by selecting the appropriate settings on your email program to disable image and refraining from clicking on any links in email messages.</p>
      <p>By continuing to browse or use our Sites and services, you agree that we can store and access cookies and other tracking technologies as described herein.</p>
    </div>
    <div> 
      <h2>How we protect your data </h2>
      <p>CIGAM has in place appropriate technological and operational security processes designed to protect personally identifiable information from loss, misuse, alteration or destruction. Only authorized employees and contractors will have access to any data provided by you, and that access is limited by need. Each employee or contractor having access to any personally-identifiable information is obligated to maintain its confidentiality. Although we take steps that are generally accepted as industry standard to protect your personally identifiable information, CIGAM cannot guarantee that your personally identifiable information will not become accessible to unauthorized persons and CIGAM cannot be responsible for any actions resulting from a breach of security when information is supplied over the internet or any public computer network.</p>
      <h2>Links to other websites</h2>
      <p>This website contains links to other Web sites not operated by CIGAM.</p>
      <p>CIGAM is not responsible for the privacy practices or the content of any non-CIGAM web sites to which we link from the Site. We are not responsible for the protection and privacy of any information you provide whilst visiting other websites and sites not governed by our Privacy Statement. We cannot control the content or security of such websites. We cannot be held responsible for any loss or damage incurred by a user as a result of visiting such websites. No links are intended to be, nor should be construed as, an endorsement of any kind by us of that other website.</p>
    </div>
    <div>
      <h2>Compliance with law </h2>
      <p>CIGAM complies with all applicable privacy laws and regulations. CIGAM may be compelled to surrender personal user or customer information to legal authorities if presented with a court subpoena or similar legal or administrative order, or as required or permitted by the laws, rules and regulations of any nation, state or other applicable jurisdiction. Also, in the event of a violation of the terms and conditions of use of the Site or a violation of any restrictions on the use of materials provided in or through the Site, we may disclose personal user information to our affected business partners or legal authorities</p>
    </div>
    <div>
      <h2>Access Rights </h2>
      <p>In accordance with applicable data protection laws, including but not limited to the GDPR and the Personal Data Protection Act (PDPA), you have a right to request a copy of the personal information we hold about you and details of how we use that information. If any of the information held about you is incorrect or out of date, you have the right to amend or rectify it, please follow the process outlined below and we will amend our records where appropriate. You also have the right to require us to erase your personal data, stop processing your personal data, restricting the processing of your personal information, right of portability of your personal information and/or to withdraw your consent to the processing. This may not apply if there are other legal justifications to continue processing. If you think we may have incorrect personal information or would like a copy of the personal information we hold on you, or to exercise any other data protection right, please contact us. Please note that we need you to prove who you are before we can provide you with any information.<br/>You also have a right to lodge a complaint with relevant supervisory authority.</p>
    </div>
    <div>
      <h2>Children</h2>
      <p>CIGAM understands the importance of protecting children's privacy, particularly in their online interactions. This Site is not designed for and does not intentionally target or solicit to children 18 years of age and younger.</p>
    </div>
    <div>
      <h2>Use of CIGAM website </h2>
      <p>Your access to and use of the Site are subject to this Privacy Statement and certain other terms and conditions, contained in our Terms and Conditions of Use.</p>
    </div>
    <div>
      <h2>Contact us </h2>
      <p>For further questions you may contact the appropriate data protection point of contact:</p>
      <p>CIGAM GLOBAL<br/>
          Level 18, Park Ventures Ecoplex<br/>
          57 Wireless Road, Lumpini, Pathumwan<br/>
          Bangkok 10330 Thailand<br/>
          <a href="mailto:info@cigamglobal.com">info@cigamglobal.com</a><br/>
          +66 2 309 3626</p>
    </div>


</div>
</section>
</Layout>
)
}

export default injectIntl(PrivacyPolicyPage)


